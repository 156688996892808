import Button from "components/atoms/Button";
import InputField from "components/atoms/InputField";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { MonthOptions, PageMode } from "utils/constant";
import RegmalFirst from "services/RegmalFirst";
import { useEffect } from "react";
import ActionButton from "components/atoms/ActionButton";
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import TableDataComponent from "components/organisms/TableData";
import { confirmAlert } from "react-confirm-alert";
import {
  errorToastNotif,
  removeDataFromLocalStorage,
  setDataToLocalStorage,
} from "utils/common";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import InputDate from "components/atoms/InputDate";
import InputSelect from "components/atoms/InputSelect";

const RegmalFirstService = new RegmalFirst();

const TableComponent = ({ changeMode }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [keyword, setKeyword] = useState("");
  const [regmalState, setRegmalState] = useState({
    page: 1,
    limit: 10,
    data: [],
    totalData: 0,
    currentPage: 1,
    nextPage: null,
    previousPage: null,
  });

  const [filterState, setFilterState] = useState({
    selected_year: "",
    selected_month: "",
  });

  const history = useHistory();

  const { currentUser } = useSelector((state) => state.authReducer);

  const getAllRegmals = async () => {
    let searchBy = `fasyankes_origin_id${keyword !== "" ? `,name` : ""}`;
    let searchValue = `${currentUser.fasyankes_id}${
      keyword !== "" ? `,${keyword}` : ""
    }`;

    const searchByDate = filterState?.selected_year && filterState?.selected_month ? "|YYYY-MM"
      : filterState?.selected_year ? "|YYYY" : filterState?.selected_month ? "|MM" : "";
    const searchValueDate = filterState?.selected_year && filterState?.selected_month
      ? `,${filterState?.selected_year}-${filterState?.selected_month}`
      : filterState?.selected_year ? `,${filterState?.selected_year}`
        : filterState?.selected_month ? `,${filterState?.selected_month}` : "";

    if (filterState.selected_year || filterState.selected_month) {
      searchBy += `,patient_visite_date${searchByDate}`;
      searchValue += searchValueDate;
    }

    setIsLoading(true);
    try {
      const response = await RegmalFirstService.getAll({
        page: regmalState.page,
        limit: regmalState.limit,
        searchBy: searchBy,
        searchValue: searchValue,
      });

      setRegmalState((prevState) => ({
        ...prevState,
        data: response.regmals,
        limit: response.limit,
        currentPage: response.currentPage,
        totalData: response.total,
        nextPage: response.nextPage,
        previousPage: response.previousPage,
      }));
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getAllRegmals();
  }, [keyword, regmalState.page]);

  const onPageChange = (page) => {
    setRegmalState((prevState) => ({
      ...prevState,
      page: page,
    }));
  };

  const onDataSearch = (e) => {
    const keyword = e.target.value;
    setKeyword(keyword);
  };

  const onRemoveData = (id) => {
    confirmAlert({
      title: "Apakah anda yakin ingin menghapus data?",
      message: "Data akan dihapus secara permanen",
      buttons: [
        {
          label: "Ya",
          onClick: async () => {
            await RegmalFirstService.remove(id)
              .then((res) => {
                getAllRegmals();
                toast.success("Data Register Malaria 1 Berhasil Dihapus", {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  theme: "colored",
                });
              })
              .catch((err) => {
                console.log(err);
                errorToastNotif(err);
              });
          },
        },
        {
          label: "Tidak",
        },
      ],
    });
  };

  const onEditData = (item) => {
    setDataToLocalStorage("SELECTED_ITEM", JSON.stringify(item));
    changeMode(PageMode.edit);
  };

  const onViewDetailData = (id) => {
    history.push(`/detail-register-malaria-1/${id}`);
  };

  const columns = [
    {
      header: "Aksi",
      accessorKey: "action",
      cell: ({ row }) => (
        <div className="button-actions">
          {(row.original.regmal_reference.status === "Belum" ||
            row.original.regmal_reference.status === null) && (
            <>
              {!row.original.is_pe && (
                <ActionButton
                  onClick={() => onEditData(row.original)}
                  btnType={"edit"}
                />
              )}
              <ActionButton
                onClick={() => onRemoveData(row.original.id)}
                btnType={"delete"}
              />
            </>
          )}
          <ActionButton
            onClick={() => onViewDetailData(row.original.id)}
            btnType={"detail"}
          />
        </div>
      ),
    },
    {
      header: "Tanggal Kunjungan Pasien",
      accessorKey: "patient_visite_date",
    },
    {
      header: "Dirujuk Ke Faskes Lain",
      accessorKey: "referred_to_other_faskes",
    },
    {
      header: "No KTP",
      accessorKey: "ktp",
    },
    {
      header: "Nama",
      accessorKey: "name",
    },
    {
      header: "Usia",
      accessorKey: "age",
      cell: ({ row }) => {
        return `${row.original.age} ${row.original?.age_type}`;
      },
    },
    {
      header: "Jenis Kelamin",
      accessorKey: "gender",
    },
    {
      header: "Alamat",
      accessorKey: "address",
    },
  ];

  const tableInstance = useReactTable({
    columns,
    data: regmalState?.data,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <>
      <h1 className="page-title">Register Malaria 1</h1>
      <div className="card-filter">
        <div className="filter-item">
          <InputDate
            placeholder={"Pilih Tahun"}
            name={"selected_year"}
            dateFormat={"YYYY"}
            onChange={(e) => {
              if(e){
                const selectedYear = moment(e).format("YYYY");
                setFilterState({
                  ...filterState,
                  selected_year: selectedYear,
                });
              }else{
                setFilterState({
                  ...filterState,
                  selected_year: "",
                });
              }
            }}
            value={filterState.selected_year}
          />
        </div>
        <div className="filter-item">
          <InputSelect
            placeholder={"Pilih Bulan"}
            data={MonthOptions}
            onChange={(e) => {
              const selectedValue = e ? e.value : "";
              setFilterState({
                ...filterState,
                selected_month: selectedValue,
              });
            }}
            value={MonthOptions.find(
              (item) => item.value === filterState.selected_month
            )}
          />
        </div>
        <Button
          variant={"primary"}
          size={"normal"}
          onClick={() => getAllRegmals()}
        >
          Terapkan
        </Button>
      </div>
      <div
        className="action-wrapper"
        style={{ marginTop: "24px", marginBottom: "24px" }}
      >
        <div className="action-button">
          {currentUser?.mode === "Online" && (
            <Button
              variant={"primary"}
              size={"normal"}
              onClick={() => {
                changeMode(PageMode.add);
                removeDataFromLocalStorage("REGMAL_PERSONAL_DATA");
                removeDataFromLocalStorage("REGMAL_DISCOVERY_STATUS");
              }}
            >
              Tambah Data Register Malaria 1
            </Button>
          )}
        </div>
        <div className="action-search">
          <InputField
            placeholder={"Cari berdasarkan nama disini..."}
            onChange={onDataSearch}
            value={keyword}
          />
        </div>
      </div>
      {/* Table Component Here */}
      <TableDataComponent
        columnLength={columns.length}
        tableInstance={tableInstance}
        onPageChange={onPageChange}
        loading={isLoading}
        currentPage={regmalState?.currentPage}
        limit={regmalState?.limit}
        nextPage={regmalState?.nextPage}
        previousPage={regmalState?.previousPage}
        total={regmalState?.totalData}
      />
    </>
  );
};

export default TableComponent;
