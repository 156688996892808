import React, { Component } from "react";
import { PlusCircle } from "react-feather";
import { TableComponent } from "components";
import { getCurrentUserFromLocalStorage } from "utils/common";

export default class Table extends Component {
  render() {
    const {
      data,
      changeMode,
      loading,
      onEdit,
      onRemove,
      onPageChange,
      onGetFile,
      onGetLetter,
    } = this.props;

    const currentUser = getCurrentUserFromLocalStorage();
    return (
      <div>
        <div className="row">
          <div className="col-sm-8">
            <button
              className="btn btn-teal font-weight-bold"
              onClick={() => changeMode("add")}
            >
              <PlusCircle className="mr-2" />
              Tambah Data Permintaan Logistik
            </button>
          </div>
        </div>

        {currentUser && currentUser.fasyankes_type_id === "jnsfas-01" && (
          <TableComponent
            loading={loading}
            data={data?.logistic_request_stocks}
            headers={[
              "Tahun Pengajuan",
              "Provinsi",
              "Kabupaten",
              "Kecamatan",
              "Fasyankes",
              "Tanggal Surat",
              "No Surat",
              "Jenis Permintaan",
              "Jenis Logistik",
              "Jumlah yang diminta",
              "Satuan",
              "Jumlah yang dipenuhi",
              "Satuan",
              "Status",
              "Surat Permintaan",
              "Surat Pemenuhan",
            ]}
            cols={[
              "year_submission",
              ["province_id", "|", 1],
              ["district_id", "|", 1],
              ["subdistrict_id", "|", 1],
              ["fasyankes_id", "|", 1],
              "letter_date",
              "letter_number",
              "request_type",
              "logistic_type",
              "request_total",
              "unit",
              "fulfilled_total",
              "fulfilled_unit",
              "status",
              "letter_upload",
              "fulfilled_letter_upload",
            ]}
            // btnEdit={{ onClick: onEdit }}
            // btnRemove={{ onClick: onRemove }}
            currentPage={data?.currentPage}
            total={data?.total}
            limit={data?.limit}
            nextPage={data?.nextPage}
            previousPage={data?.previousPage}
            onPageChange={onPageChange}
            onGetFile={onGetFile}
            onGetLetter={onGetLetter}
            withoutActionBtn={true}
          />
        )}

        {currentUser && currentUser.fasyankes_type_id !== "jnsfas-01" && (
          <TableComponent
            loading={loading}
            data={data?.logistic_request_stocks}
            headers={[
              "Tahun Pengajuan",
              "Provinsi",
              "Kabupaten",
              "Fasyankes",
              "Tanggal Surat",
              "No Surat",
              "Jenis Permintaan",
              "Jenis Logistik",
              "Jumlah yang diminta",
              "Satuan",
              "Jumlah yang dipenuhi",
              "Satuan",
              "Status",
              "Surat Permintaan",
            ]}
            cols={[
              "year_submission",
              ["province_id", "|", 1],
              ["district_id", "|", 1],
              ["fasyankes_id", "|", 1],
              "letter_date",
              "letter_number",
              "request_type",
              "logistic_type",
              "request_total",
              "unit",
              "fulfilled_total",
              "fulfilled_unit",
              "status",
              "letter_upload",
            ]}
            btnEdit={{ onClick: onEdit }}
            btnRemove={{ onClick: onRemove }}
            currentPage={data?.currentPage}
            total={data?.total}
            limit={data?.limit}
            nextPage={data?.nextPage}
            previousPage={data?.previousPage}
            onPageChange={onPageChange}
            onGetFile={onGetFile}
            withoutActionBtn={true}
          />
        )}
      </div>
    );
  }
}
