import React, { Component } from "react";
import { PageMode, Source } from "../../../utils/constant";
import Table from "./components/Table";
import Form from "./components/Form";
import Screening from "services/Screening";
import Wilayah from "services/Wilayah";
import { ArrowLeft } from "react-feather";
import { connect } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import moment from "moment";

const ScreeningService = new Screening();
const WilatahService = new Wilayah();

class MalariaScreening extends Component {
  _isMounted = true;

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      mode: PageMode.view,
      selected_year: "",
      loading: true,
      selectedItem: null,
      page: 1,
      limit: 10,
      currentPage: 1,
    };
  }

  componentDidMount() {
    document.title = "SISMAL | Integritas Malaria-KIA";

    this._isMounted = true;
    this.getAllData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  changeMode = (mode) => {
    this.setState({ mode }, () => {
      if (mode === PageMode.view) this.getAllData();
    });
  };

  getAllData = () => {
    const { user } = this.props;
    const { page, limit } = this.state;
    const searchBy = "fasyankes_id";
    const searchValue = `${user.fasyankes_id}`;
    this.setState({ loading: true, selectedItem: null }, async () => {
      await ScreeningService.getAll({
        page,
        limit,
        searchBy,
        searchValue,
      })
        .then((data) => {
          if (this._isMounted) {
            this.setState({ loading: false, data });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading: false });
        });
    });
  };

  onEdit = (id) => {
    this.setState({ selectedItem: id }, () => {
      this.changeMode(PageMode.edit);
    });
  };

  onRemove = async (id) => {
    confirmAlert({
      title: "Anda yakin ingin Menghapus data?",
      message: "Are you sure want to delete this data?",
      buttons: [
        {
          label: "Ya",
          onClick: () => {
            ScreeningService.remove(id)
              .then((res) => {
                this.getAllData();
                toast.success("Data berhasil dihapus", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              })
              .catch((err) => {
                console.log(err);

                if (err.resonse.data.message === ErrorMessage.UserNotVerified) {
                  toast.error("Akun anda belum diverifikasi", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                }

                if (
                  err.response.data.message ===
                  ErrorMessage.UserFaskesNotHaveAccess
                ) {
                  toast.error("Akun anda tidak memiliki akses", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                }
              });
          },
        },
        {
          label: "Tidak",
        },
      ],
    });
  };

  handleChangeYear = (e) => {
    this.setState({
      selected_year: moment(e).format("YYYY"),
    });
  };

  onFilterData = (e) => {
    this.setState({ loading: true, selectedItem: null, page: 1 }, async () => {
      let searchBy = "fasyankes_id,year";
      let searchValue = `${this.props.user.fasyankes_id},${this.state.selected_year}`;

      await ScreeningService.getAll({
        page: this.state.page,
        limit: this.state.limit,
        searchBy: searchBy,
        searchValue: searchValue,
      })
        .then((data) => {
          if (this._isMounted) {
            this.setState({ loading: false, data });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading: false });
        });
    });
  };

  onPageChange = (page) => {
    this.setState({ page }, () => {
      this.getAllData({
        ...this.state,
        page,
      });
    });
  };

  render() {
    const { data, mode, loading, page, selectedItem } = this.state;

    return (
      <div className="dashboard-wrapper">
        {mode !== PageMode.view && (
          <div
            className="my-3"
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.changeMode(PageMode.view);
            }}
          >
            <ArrowLeft />
            <span>Kembali</span>
          </div>
        )}

        <h1 className="title font-weight-bold">
          {mode === PageMode.view
            ? "Integrasi Malaria-KIA"
            : "Input data sasaran"}
        </h1>
        <div className="content-wrapper mt-4">
          {mode === PageMode.view && (
            <Table
              data={data}
              handleChangeYear={this.handleChangeYear}
              selected_year={this.state.selected_year}
              onFilterData={this.onFilterData}
              loading={loading}
              currentPage={page}
              onEdit={this.onEdit}
              onAddAchievmentData={this.onAddAchievmentData}
              onRemove={this.onRemove}
              changeMode={this.changeMode}
              onPageChange={this.onPageChange}
              currentUser={this.props.user}
            />
          )}
          {(mode === PageMode.add || mode === PageMode.edit) && (
            <Form
              mode={mode}
              changeMode={this.changeMode}
              data={selectedItem}
              currentUser={this.props.user}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authReducer.currentUser,
  };
};

export default connect(mapStateToProps)(MalariaScreening);
