import React, { Component, Fragment } from "react";
import Screening from "services/Screening";
import Achievment from "services/Achieved";
import { ErrorMessage, PageMode } from "utils/constant";
import TableComponent from "./components/Table";
import FormComponent from "./components/Form";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";

const ScreeningService = new Screening();
const AchievmentService = new Achievment();

export default class AchievedData extends Component {
  _isMounted = true;
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 10,
      targetData: {},
      achievedData: [],
      loading: true,
      mode: PageMode.view,
      selectedItem: null,
      currentPage: 1,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.getSasaranDataById();
    this.getCapaianDataBySasaran();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  changeMode = (mode) => {
    this.setState({ mode }, () => {
      if (mode === PageMode.view) {
        this.getSasaranDataById();
        this.getCapaianDataBySasaran();
      }
    });
  };

  onPageChange = (page) => {
    this.setState({ page }, () => {
      this.getCapaianDataBySasaran();
    });
  };

  getSasaranDataById = async () => {
    const _id = this.props.match.params.id;

    await ScreeningService.getOne(_id)
      .then((res) => {
        this.setState({
          loading: false,
          targetData: res,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getCapaianDataBySasaran = async () => {
    const idSasaran = this.props.match.params.id;
    const { page, limit } = this.state;
    await AchievmentService.getAll({
      searchBy: "kia_screening_target_id",
      searchValue: `${idSasaran}`,
      page,
      limit,
    })
      .then((res) => {
        this.setState({
          loading: false,
          achievedData: res,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onEdit = (item) => {
    this.setState({ selectedItem: item }, () => {
      this.changeMode(PageMode.edit);
    });
  };

  onRemove = (id) => {
    confirmAlert({
      title: "Anda yakin ingin menghapus data ini?",
      message: "Are you sure want to delete this data?",
      buttons: [
        {
          label: "Ya",
          onClick: () =>
            AchievmentService.remove(id)
              .then((data) => {
                if (this._isMounted) {
                  this.getSasaranDataById();
                  this.getCapaianDataBySasaran();
                }
                toast.success("Data berhasil dihapus", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              })
              .catch((err) => {
                const errMsg = err.response.data.message ?? "";
                toast.error(
                  <>
                    Data gagal dihapus! <br />
                    Error: {errMsg}
                  </>,
                  {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  }
                );
                console.log(err);

                if (err.resonse.data.message === ErrorMessage.UserNotVerified) {
                  toast.error("Akun anda belum diverifikasi", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                }

                if (
                  err.response.data.message ===
                  ErrorMessage.UserFaskesNotHaveAccess
                ) {
                  toast.error("Akun anda tidak memiliki akses", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                }
              }),
        },
        {
          label: "Tidak",
        },
      ],
    });
  };

  render() {
    const { targetData, achievedData, mode, selectedItem } = this.state;

    return (
      <Fragment>
        {mode === PageMode.view && (
          <TableComponent
            targetData={targetData}
            data={achievedData}
            onEdit={this.onEdit}
            onRemove={this.onRemove}
            changeMode={this.changeMode}
            loading={this.state.loading}
            onPageChange={this.onPageChange}
          />
        )}
        {(mode === PageMode.add || mode === PageMode.edit) && (
          <FormComponent
            mode={mode}
            targetData={targetData}
            changeMode={this.changeMode}
            data={selectedItem}
          />
        )}
      </Fragment>
    );
  }
}
