import React from "react";
import { monthConverter } from "utils/common";
import { UserLevel } from "utils/constant";

const TableComponent = ({
  reportsData,
  currentLevel,
  tableRef,
  reportInfo,
}) => {
  const calculateTotal = () => {
    return reportsData.reduce(
      (total, item) => {
        total.total_jml_kasus_positif += parseInt(item.jml_kasus_positif ?? 0);
        total.total_jml_kasus_pe += parseInt(item.jml_kasus_pe ?? 0);
        total.total_jml_kasus_survey_kontak += parseInt(
          item.jml_kasus_survey_kontak ?? 0
        );
        total.total_jml_mikro_bawah_5_thn += parseInt(
          item.jml_mikro_bawah_5_thn ?? 0
        );
        total.total_jml_rdt_bawah_5_thn += parseInt(
          item.jml_rdt_bawah_5_thn ?? 0
        );
        total.total_jml_mikro_atas_5_thn += parseInt(
          item.jml_mikro_atas_5_thn ?? 0
        );
        total.total_jml_rdt_atas_5_thn += parseInt(
          item.jml_rdt_atas_5_thn ?? 0
        );
        total.total_jml_kasus_perindukan_nyamuk += parseInt(
          item.jml_kasus_perindukan_nyamuk ?? 0
        );
        total.total_jml_kasus_baru += parseInt(item.jml_kasus_baru ?? 0);
        total.total_jml_kasus_relaps += parseInt(item.jml_kasus_relaps ?? 0);
        total.total_jml_penularan_nyamuk += parseInt(
          item.jml_penularan_nyamuk ?? 0
        );
        total.total_jml_penularan_transfusi_darah += parseInt(
          item.jml_penularan_transfusi_darah ?? 0
        );
        total.total_jml_penularan_konginetal += parseInt(
          item.jml_penularan_konginetal ?? 0
        );
        total.total_jml_kasus_mmp += parseInt(item.jml_kasus_mmp ?? 0);
        total.total_jml_penularan_luar_negara += parseInt(
          item.jml_penularan_luar_negara ?? 0
        );
        total.total_jml_kasus_indigenous += parseInt(
          item.jml_kasus_indigenous ?? 0
        );
        total.total_jml_kasus_import += parseInt(item.jml_kasus_import ?? 0);
        total.total_jml_kasus_relaps_indigenous += parseInt(
          item.jml_kasus_relaps_indigenous ?? 0
        );
        total.total_jml_kasus_relaps_import += parseInt(
          item.jml_kasus_relaps_import ?? 0
        );
        total.total_jml_kasus_introduce += parseInt(
          item.jml_kasus_introduce ?? 0
        );

        return total;
      },
      {
        total_jml_kasus_positif: 0,
        total_jml_kasus_pe: 0,
        total_jml_kasus_survey_kontak: 0,
        total_jml_kasus_survey_kontak: 0,
        total_jml_mikro_bawah_5_thn: 0,
        total_jml_rdt_bawah_5_thn: 0,
        total_jml_mikro_atas_5_thn: 0,
        total_jml_rdt_atas_5_thn: 0,
        total_jml_kasus_perindukan_nyamuk: 0,
        total_jml_kasus_baru: 0,
        total_jml_kasus_relaps: 0,
        total_jml_penularan_nyamuk: 0,
        total_jml_penularan_transfusi_darah: 0,
        total_jml_penularan_konginetal: 0,
        total_jml_kasus_mmp: 0,
        total_jml_penularan_luar_negara: 0,
        total_jml_kasus_indigenous: 0,
        total_jml_kasus_import: 0,
        total_jml_kasus_relaps_indigenous: 0,
        total_jml_kasus_relaps_import: 0,
        total_jml_kasus_introduce: 0,
      }
    );
  };

  const renderTableByLevel = () => {
    const total = calculateTotal();

    switch (currentLevel) {
      case UserLevel.National.toLowerCase():
        return (
          <>
            <table>
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Provinsi Faskes Domisili</th>
                  <th>Kab/Kota Faskes Domisili</th>
                  <th>
                    Jumlah Kasus Positif <br />
                    Ternotifikasi Yang Harus Dilakukan PE
                  </th>
                  <th>
                    Jumlah Kasus Positif <br />
                    Ternotifikasi Yang Sudah Dilakukan PE
                  </th>
                  <th>
                    Jumlah Kasus Yang <br /> Dilakukan Survei Kontak
                  </th>
                  <th>
                    Jumlah Pemeriksaan <br /> Mikroskopis (Negatif) <br /> Usia{" "}
                    {"<"} 5 Tahun.
                  </th>
                  <th>
                    Jumlah Pemeriksaan <br /> RDT (Negatif) <br /> Usia {"<"} 5
                    Tahun.
                  </th>
                  <th>
                    Jumlah Pemeriksaan <br /> Mikroskopis (Negatif) <br /> Usia{" "}
                    {">="} 5 Tahun.
                  </th>
                  <th>
                    Jumlah Pemeriksaan <br /> RDT (Negatif) <br /> Usia {">="} 5
                    Tahun.
                  </th>
                  <th>
                    Jumlah Kasus <br /> Yang Dilakukan <br /> Identifikasi
                    Perindukan <br /> Nyamuk
                  </th>
                  <th>Jumlah Kasus Baru</th>
                  <th>
                    Jumlah Kasus <br /> Relaps/Kambuh
                  </th>
                  <th>
                    Jumlah Penularan <br /> Kasus Melalui <br /> Nyamuk
                  </th>
                  <th>
                    Jumlah Penularan <br /> Kasus Melalui <br /> Transfusi Darah
                  </th>
                  <th>
                    Jumlah Penularan <br /> Kasus Melalui <br /> Kongenital
                  </th>
                  <th>
                    Jumlah Kasus <br /> Pada MMP
                  </th>
                  <th>
                    Jumlah Kasus <br /> Asal Penularan Luar Negara
                  </th>
                  <th>
                    Jumlah Kasus <br /> Indigenous
                  </th>
                  <th>
                    Jumlah Kasus <br /> Impor
                  </th>
                  <th>
                    Jumlah Kasus <br /> Relaps Indigenous
                  </th>
                  <th>
                    Jumlah Kasus <br /> Relaps Impor
                  </th>
                  <th>
                    Jumlah Kasus <br /> Introduce
                  </th>
                </tr>
              </thead>
              <tbody>
                {reportsData.length !== 0 ? (
                  reportsData?.map((item, index) => (
                    <tr key={item?.id}>
                      <td>{index + 1}</td>
                      <td>{item?.prov_name}</td>
                      <td>{item?.kab_name}</td>
                      <td>{item?.jml_kasus_positif}</td>
                      <td>{item?.jml_kasus_pe}</td>
                      <td>{item?.jml_kasus_survey_kontak}</td>
                      <td>{item?.jml_mikro_bawah_5_thn}</td>
                      <td>{item?.jml_rdt_bawah_5_thn}</td>
                      <td>{item?.jml_mikro_atas_5_thn}</td>
                      <td>{item?.jml_rdt_atas_5_thn}</td>
                      <td>{item?.jml_kasus_perindukan_nyamuk}</td>
                      <td>{item?.jml_kasus_baru}</td>
                      <td>{item?.jml_kasus_relaps}</td>
                      <td>{item?.jml_penularan_nyamuk}</td>
                      <td>{item?.jml_penularan_transfusi_darah}</td>
                      <td>{item?.jml_penularan_konginetal}</td>
                      <td>{item?.jml_kasus_mmp}</td>
                      <td>{item?.jml_penularan_luar_negara}</td>
                      <td>{item?.jml_kasus_indigenous}</td>
                      <td>{item?.jml_kasus_import}</td>
                      <td>{item?.jml_kasus_relaps_indigenous}</td>
                      <td>{item?.jml_kasus_relaps_import}</td>
                      <td>{item?.jml_kasus_introduce}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={23}>Tidak Ada Data</td>
                  </tr>
                )}
                <tr style={{ backgroundColor: "#43766C" }}>
                  <td style={{ color: "#FFF" }} colSpan={3}>
                    Total
                  </td>
                  <td style={{ color: "#FFF" }}>
                    {total.total_jml_kasus_positif}
                  </td>
                  <td style={{ color: "#FFF" }}>{total.total_jml_kasus_pe}</td>
                  <td style={{ color: "#FFF" }}>
                    {total.total_jml_kasus_survey_kontak}
                  </td>
                  <td style={{ color: "#FFF" }}>
                    {total.total_jml_mikro_bawah_5_thn}
                  </td>
                  <td style={{ color: "#FFF" }}>
                    {total.total_jml_rdt_bawah_5_thn}
                  </td>
                  <td style={{ color: "#FFF" }}>
                    {total.total_jml_mikro_atas_5_thn}
                  </td>
                  <td style={{ color: "#FFF" }}>
                    {total.total_jml_rdt_atas_5_thn}
                  </td>
                  <td style={{ color: "#FFF" }}>
                    {total.total_jml_kasus_perindukan_nyamuk}
                  </td>
                  <td style={{ color: "#FFF" }}>
                    {total.total_jml_kasus_baru}
                  </td>
                  <td style={{ color: "#FFF" }}>
                    {total.total_jml_kasus_relaps}
                  </td>
                  <td style={{ color: "#FFF" }}>
                    {total.total_jml_penularan_nyamuk}
                  </td>
                  <td style={{ color: "#FFF" }}>
                    {total.total_jml_penularan_transfusi_darah}
                  </td>
                  <td style={{ color: "#FFF" }}>
                    {total.total_jml_penularan_konginetal}
                  </td>
                  <td style={{ color: "#FFF" }}>{total.total_jml_kasus_mmp}</td>
                  <td style={{ color: "#FFF" }}>
                    {total.total_jml_penularan_luar_negara}
                  </td>
                  <td style={{ color: "#FFF" }}>
                    {total.total_jml_kasus_indigenous}
                  </td>
                  <td style={{ color: "#FFF" }}>
                    {total.total_jml_kasus_import}
                  </td>
                  <td style={{ color: "#FFF" }}>
                    {total.total_jml_kasus_relaps_indigenous}
                  </td>
                  <td style={{ color: "#FFF" }}>
                    {total.total_jml_kasus_relaps_import}
                  </td>
                  <td style={{ color: "#FFF" }}>
                    {total.total_jml_kasus_introduce}
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        );
      case UserLevel.Province.toLowerCase():
        return (
          <>
            <table>
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Provinsi Faskes Domisili</th>
                  <th>Kab/Kota Faskes Domisili</th>
                  <th>
                    Jumlah Kasus Positif <br />
                    Ternotifikasi Yang Harus Dilakukan PE
                  </th>
                  <th>
                    Jumlah Kasus Positif <br />
                    Ternotifikasi Yang Sudah Dilakukan PE
                  </th>
                  <th>
                    Jumlah Kasus Yang <br /> Dilakukan Survei Kontak
                  </th>
                  <th>
                    Jumlah Pemeriksaan <br /> Mikroskopis (Negatif) <br /> Usia{" "}
                    {"<"} 5 Tahun.
                  </th>
                  <th>
                    Jumlah Pemeriksaan <br /> RDT (Negatif) <br /> Usia {"<"} 5
                    Tahun.
                  </th>
                  <th>
                    Jumlah Pemeriksaan <br /> Mikroskopis (Negatif) <br /> Usia{" "}
                    {">="} 5 Tahun.
                  </th>
                  <th>
                    Jumlah Pemeriksaan <br /> RDT (Negatif) <br /> Usia {">="} 5
                    Tahun.
                  </th>
                  <th>
                    Jumlah Kasus Yang <br /> Dilakukan Identifikasi <br />{" "}
                    Perindukan Nyamuk
                  </th>
                  <th>Jumlah Kasus Baru</th>
                  <th>Jumlah Kasus Relaps/Kambuh</th>
                  <th>
                    Jumlah Penularan <br /> Kasus Melalui <br /> Nyamuk
                  </th>
                  <th>
                    Jumlah Penularan <br /> Kasus Melalui <br /> Transfusi Darah
                  </th>
                  <th>
                    Jumlah Penularan <br /> Kasus Melalui <br /> Kongenital
                  </th>
                  <th>
                    Jumlah Kasus <br /> Pada MMP
                  </th>
                  <th>
                    Jumlah Kasus <br /> Asal Penularan Luar Negara
                  </th>
                  <th>
                    Jumlah Kasus <br /> Indigenous
                  </th>
                  <th>
                    Jumlah Kasus <br /> Impor
                  </th>
                  <th>
                    Jumlah Kasus <br /> Relaps Indigenous
                  </th>
                  <th>
                    Jumlah Kasus <br /> Relaps Impor
                  </th>
                  <th>
                    Jumlah Kasus <br /> Introduce
                  </th>
                </tr>
              </thead>
              <tbody>
                {reportsData.length !== 0 ? (
                  reportsData?.map((item, index) => (
                    <tr key={item?.id}>
                      <td>{index + 1}</td>
                      <td>{item?.prov_name}</td>
                      <td>{item?.kab_name}</td>
                      <td>{item?.jml_kasus_positif}</td>
                      <td>{item?.jml_kasus_pe}</td>
                      <td>{item?.jml_kasus_survey_kontak}</td>
                      <td>{item?.jml_mikro_bawah_5_thn}</td>
                      <td>{item?.jml_rdt_bawah_5_thn}</td>
                      <td>{item?.jml_mikro_atas_5_thn}</td>
                      <td>{item?.jml_rdt_atas_5_thn}</td>
                      <td>{item?.jml_kasus_perindukan_nyamuk}</td>
                      <td>{item?.jml_kasus_baru}</td>
                      <td>{item?.jml_kasus_relaps}</td>
                      <td>{item?.jml_penularan_nyamuk}</td>
                      <td>{item?.jml_penularan_transfusi_darah}</td>
                      <td>{item?.jml_penularan_konginetal}</td>
                      <td>{item?.jml_kasus_mmp}</td>
                      <td>{item?.jml_penularan_luar_negara}</td>
                      <td>{item?.jml_kasus_indigenous}</td>
                      <td>{item?.jml_kasus_import}</td>
                      <td>{item?.jml_kasus_relaps_indigenous}</td>
                      <td>{item?.jml_kasus_relaps_import}</td>
                      <td>{item?.jml_kasus_introduce}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={23}>Tidak Ada Data</td>
                  </tr>
                )}
                <tr style={{ backgroundColor: "#43766C" }}>
                  <td style={{ color: "#FFF" }} colSpan={3}>
                    Total
                  </td>
                  <td style={{ color: "#FFF" }}>
                    {total.total_jml_kasus_positif}
                  </td>
                  <td style={{ color: "#FFF" }}>{total.total_jml_kasus_pe}</td>
                  <td style={{ color: "#FFF" }}>
                    {total.total_jml_kasus_survey_kontak}
                  </td>
                  <td style={{ color: "#FFF" }}>
                    {total.total_jml_mikro_bawah_5_thn}
                  </td>
                  <td style={{ color: "#FFF" }}>
                    {total.total_jml_rdt_bawah_5_thn}
                  </td>
                  <td style={{ color: "#FFF" }}>
                    {total.total_jml_mikro_atas_5_thn}
                  </td>
                  <td style={{ color: "#FFF" }}>
                    {total.total_jml_rdt_atas_5_thn}
                  </td>
                  <td style={{ color: "#FFF" }}>
                    {total.total_jml_kasus_perindukan_nyamuk}
                  </td>
                  <td style={{ color: "#FFF" }}>
                    {total.total_jml_kasus_baru}
                  </td>
                  <td style={{ color: "#FFF" }}>
                    {total.total_jml_kasus_relaps}
                  </td>
                  <td style={{ color: "#FFF" }}>
                    {total.total_jml_penularan_nyamuk}
                  </td>
                  <td style={{ color: "#FFF" }}>
                    {total.total_jml_penularan_transfusi_darah}
                  </td>
                  <td style={{ color: "#FFF" }}>
                    {total.total_jml_penularan_konginetal}
                  </td>
                  <td style={{ color: "#FFF" }}>{total.total_jml_kasus_mmp}</td>
                  <td style={{ color: "#FFF" }}>
                    {total.total_jml_penularan_luar_negara}
                  </td>
                  <td style={{ color: "#FFF" }}>
                    {total.total_jml_kasus_indigenous}
                  </td>
                  <td style={{ color: "#FFF" }}>
                    {total.total_jml_kasus_import}
                  </td>
                  <td style={{ color: "#FFF" }}>
                    {total.total_jml_kasus_relaps_indigenous}
                  </td>
                  <td style={{ color: "#FFF" }}>
                    {total.total_jml_kasus_relaps_import}
                  </td>
                  <td style={{ color: "#FFF" }}>
                    {total.total_jml_kasus_introduce}
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        );
      case UserLevel.District.toLowerCase():
        return (
          <>
            <table>
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Kab/Kota Faskes Domisili</th>
                  <th>Faskes Domisili</th>
                  <th>
                    Jumlah Kasus Positif <br />
                    Ternotifikasi Yang Harus Dilakukan PE
                  </th>
                  <th>
                    Jumlah Kasus Positif <br />
                    Ternotifikasi Yang Sudah Dilakukan PE
                  </th>
                  <th>
                    Jumlah Kasus <br /> Yang Dilakukan <br /> Survei Kontak
                  </th>
                  <th>
                    Jumlah Pemeriksaan <br /> Mikroskopis (Negatif) <br /> Usia{" "}
                    {"<"} 5 Tahun.
                  </th>
                  <th>
                    Jumlah Pemeriksaan <br /> RDT (Negatif) <br /> Usia {"<"} 5
                    Tahun.
                  </th>
                  <th>
                    Jumlah Pemeriksaan <br /> Mikroskopis (Negatif) <br /> Usia{" "}
                    {">="} 5 Tahun.
                  </th>
                  <th>
                    Jumlah Pemeriksaan <br /> RDT (Negatif) <br /> Usia {">="} 5
                    Tahun.
                  </th>
                  <th>
                    Jumlah Kasus <br /> Yang Dilakukan <br /> Identifikasi
                    Perindukan Nyamuk
                  </th>
                  <th>Jumlah Kasus Baru</th>
                  <th>Jumlah Kasus Relaps/Kambuh</th>
                  <th>
                    Jumlah Penularan <br /> Kasus Melalui <br /> Nyamuk
                  </th>
                  <th>
                    Jumlah Penularan <br /> Kasus Melalui <br /> Transfusi Darah
                  </th>
                  <th>
                    Jumlah Penularan <br /> Kasus Melalui <br /> Kongenital
                  </th>
                  <th>
                    Jumlah Kasus <br /> Pada MMP
                  </th>
                  <th>
                    Jumlah Kasus <br /> Asal Penularan Luar Negara
                  </th>
                  <th>
                    Jumlah Kasus <br /> Indigenous
                  </th>
                  <th>
                    Jumlah Kasus <br /> Impor
                  </th>
                  <th>
                    Jumlah Kasus <br /> Relaps Indigenous
                  </th>
                  <th>
                    Jumlah Kasus <br /> Relaps Impor
                  </th>
                  <th>
                    Jumlah Kasus <br /> Introduce
                  </th>
                </tr>
              </thead>
              <tbody>
                {reportsData.length !== 0 ? (
                  reportsData?.map((item, index) => (
                    <tr key={item?.id}>
                      <td>{index + 1}</td>
                      <td>{item?.kab_name}</td>
                      <td>{item?.faskes_name}</td>
                      <td>{item?.jml_kasus_positif}</td>
                      <td>{item?.jml_kasus_pe}</td>
                      <td>{item?.jml_kasus_survey_kontak}</td>
                      <td>{item?.jml_mikro_bawah_5_thn}</td>
                      <td>{item?.jml_rdt_bawah_5_thn}</td>
                      <td>{item?.jml_mikro_atas_5_thn}</td>
                      <td>{item?.jml_rdt_atas_5_thn}</td>
                      <td>{item?.jml_kasus_perindukan_nyamuk}</td>
                      <td>{item?.jml_kasus_baru}</td>
                      <td>{item?.jml_kasus_relaps}</td>
                      <td>{item?.jml_penularan_nyamuk}</td>
                      <td>{item?.jml_penularan_transfusi_darah}</td>
                      <td>{item?.jml_penularan_konginetal}</td>
                      <td>{item?.jml_kasus_mmp}</td>
                      <td>{item?.jml_penularan_luar_negara}</td>
                      <td>{item?.jml_kasus_indigenous}</td>
                      <td>{item?.jml_kasus_import}</td>
                      <td>{item?.jml_kasus_relaps_indigenous}</td>
                      <td>{item?.jml_kasus_relaps_import}</td>
                      <td>{item?.jml_kasus_introduce}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={23}>Tidak Ada Data</td>
                  </tr>
                )}
                <tr style={{ backgroundColor: "#43766C" }}>
                  <td style={{ color: "#FFF" }} colSpan={3}>
                    Total
                  </td>
                  <td style={{ color: "#FFF" }}>
                    {total.total_jml_kasus_positif}
                  </td>
                  <td style={{ color: "#FFF" }}>{total.total_jml_kasus_pe}</td>
                  <td style={{ color: "#FFF" }}>
                    {total.total_jml_kasus_survey_kontak}
                  </td>
                  <td style={{ color: "#FFF" }}>
                    {total.total_jml_mikro_bawah_5_thn}
                  </td>
                  <td style={{ color: "#FFF" }}>
                    {total.total_jml_rdt_bawah_5_thn}
                  </td>
                  <td style={{ color: "#FFF" }}>
                    {total.total_jml_mikro_atas_5_thn}
                  </td>
                  <td style={{ color: "#FFF" }}>
                    {total.total_jml_rdt_atas_5_thn}
                  </td>
                  <td style={{ color: "#FFF" }}>
                    {total.total_jml_kasus_perindukan_nyamuk}
                  </td>
                  <td style={{ color: "#FFF" }}>
                    {total.total_jml_kasus_baru}
                  </td>
                  <td style={{ color: "#FFF" }}>
                    {total.total_jml_kasus_relaps}
                  </td>
                  <td style={{ color: "#FFF" }}>
                    {total.total_jml_penularan_nyamuk}
                  </td>
                  <td style={{ color: "#FFF" }}>
                    {total.total_jml_penularan_transfusi_darah}
                  </td>
                  <td style={{ color: "#FFF" }}>
                    {total.total_jml_penularan_konginetal}
                  </td>
                  <td style={{ color: "#FFF" }}>{total.total_jml_kasus_mmp}</td>
                  <td style={{ color: "#FFF" }}>
                    {total.total_jml_penularan_luar_negara}
                  </td>
                  <td style={{ color: "#FFF" }}>
                    {total.total_jml_kasus_indigenous}
                  </td>
                  <td style={{ color: "#FFF" }}>
                    {total.total_jml_kasus_import}
                  </td>
                  <td style={{ color: "#FFF" }}>
                    {total.total_jml_kasus_relaps_indigenous}
                  </td>
                  <td style={{ color: "#FFF" }}>
                    {total.total_jml_kasus_relaps_import}
                  </td>
                  <td style={{ color: "#FFF" }}>
                    {total.total_jml_kasus_introduce}
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        );
      default:
        return (
          <>
            <table>
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Provinsi Faskes Pencatat</th>
                  <th>Kab/Kota Faskes Pencatat</th>
                  <th>Faskes Pencatat</th>
                  <th>Nama Pasien</th>
                  <th>
                    Alamat Lengkap <br /> dan No. Telepon Pasien
                  </th>
                  <th>Tgl Kunjungan Pasien</th>
                  <th>Faskes Melakukan PE</th>
                  <th>Kab/Kota Faskes PE</th>
                  <th>Provinsi Faskes PE</th>
                  <th>Tgl Wawancara</th>
                  <th>Survei Kontak</th>
                  <th>Tgl Survei Kontak</th>
                  <th>
                    Hasil Survei Kontak PE Usia {"<"} 5 Tahun. <br /> Jumlah
                    Pemeriksaan Mikroskopis (Negatif)
                  </th>
                  <th>
                    Hasil Survei Kontak PE Usia {"<"} 5 Tahun. <br /> Jumlah
                    Pemeriksaan RDT (Negatif)
                  </th>
                  <th>
                    Hasil Survei Kontak PE Usia {">="} 5 Tahun. <br /> Jumlah
                    Pemeriksaan Mikroskopis (Negatif)
                  </th>
                  <th>
                    Hasil Survei Kontak PE Usia {">="} 5 Tahun. <br /> Jumlah
                    Pemeriksaan RDT (Negatif)
                  </th>
                  <th>Identifikasi Perindukan Nyamuk</th>
                  <th>Tanggal Identifikasi Perindukan Nyamuk</th>
                  <th>Baru/Kambuh</th>
                  <th>Tanggal Kasus Awal</th>
                  <th>Penularan</th>
                  <th>Asal Penularan</th>
                  <th>Provinsi Asal Penularan</th>
                  <th>Kab/Kota Asal Penularan</th>
                  <th>Kecamatan Asal Penularan</th>
                  <th>Faskes Asal Penularan</th>
                  <th>Desa Asal Penularan</th>
                  <th>Dusun Asal Penularan</th>
                  <th>Alamat Penularan</th>
                  <th>Koordinat</th>
                  <th>No. Telepon Pasien</th>
                  <th>Kasus pada MMP</th>
                  <th>MMP ID</th>
                  <th>Negara Asal Penularan</th>
                  <th>Alamat Negara Asal Penularan</th>
                  <th>Klasifikasi Kasus</th>
                  <th>Jika indigenous, apakah kasus adalah introduce?</th>
                  <th>Validasi kasus indigenous oleh Kab/Kota</th>
                </tr>
              </thead>
              <tbody>
                {reportsData.length !== 0 ? (
                  reportsData?.map((item, index) => (
                    <tr key={item?.id}>
                      <td>{index + 1}</td>
                      <td>{item?.namaprop}</td>
                      <td>{item?.namakab}</td>
                      <td>{item?.faskespencatat}</td>
                      <td>{item?.namapasien}</td>
                      <td>{item?.alamat_lengkap}</td>
                      <td>{item?.tglkunjpasien}</td>
                      <td>{item?.faskes_pe}</td>
                      <td>{item?.kab_pe}</td>
                      <td>{item?.prop_pe}</td>
                      <td>{item?.case_interview_date}</td>
                      <td>{item?.contact_survey}</td>
                      <td>{item?.contact_survey_date}</td>
                      <td>{item?.bw5_mikros}</td>
                      <td>{item?.bw5_rdt}</td>
                      <td>{item?.at5_mikros}</td>
                      <td>{item?.at5_rdt}</td>
                      <td>{item?.identify_mosquito_breeding_area}</td>
                      <td>{item?.identify_mosquito_breeding_area_date}</td>
                      <td>{item?.recurrence}</td>
                      <td>{item?.initial_case_date}</td>
                      <td>{item?.transmission}</td>
                      <td>{item?.transmission_origin}</td>
                      <td>{item?.prop_asal_nular}</td>
                      <td>{item?.kab_asal_nular}</td>
                      <td>{item?.kec_asal_nular}</td>
                      <td>{item?.faskes_asal_nular}</td>
                      <td>{item?.desa_asal_nular}</td>
                      <td>{item?.dusun1}</td>
                      <td>{item?.alamat_nular}</td>
                      <td>{item?.kordinat_nular}</td>
                      <td>{item?.notelp}</td>
                      <td>{item?.mmp_case}</td>
                      <td>{item?.mmp_id}</td>
                      <td>{item?.negara_nular}</td>
                      <td>{item?.alamat_negara_nular}</td>
                      <td>{item?.case_classification}</td>
                      <td>{item?.introduce}</td>
                      <td>{item?.kab_validasi_indigenous}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={36}>Tidak Ada Data</td>
                  </tr>
                )}
              </tbody>
            </table>
          </>
        );
    }
  };

  let levelName = "";

  if (currentLevel === "national") {
    levelName = "Nasional";
  } else if (currentLevel === "province") {
    levelName = "Provinsi";
  } else if (currentLevel === "district") {
    levelName = "Kabupaten/Kota";
  } else {
    levelName = "Fasyankes";
  }

  let periodeName = "";
  if (reportInfo?.statusPeriode === "yearly") {
    periodeName = "Tahunan";
  } else {
    periodeName = "Bulanan";
  }

  let data = reportInfo?.fasyankes;
  let parts = data?.split(",");
  let faskesName = parts?.map(function (part) {
    return part?.split("|")[1];
  });
  let result = faskesName?.join(", ");

  return (
    <div
      className="table-table-responsive"
      style={{ height: "560px", overflow: "scroll" }}
      ref={tableRef}
    >
      <table>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingBottom: 0 }}>
            <h3>SISMAL v3, KASUS PE</h3>
          </th>
        </tr>
      </table>
      <table>
        <tr style={{ border: "none" }}>
          <th
            style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}
            width="200"
          >
            Periode Laporan
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            : {periodeName}
          </th>
        </tr>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            Tahun
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            : {reportInfo?.periode?.year}
          </th>
        </tr>
        {reportInfo?.statusPeriode === "monthly" && (
          <tr style={{ border: "none" }}>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              Bulan
            </th>
            <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
              : {monthConverter(reportInfo?.periode?.month)}
            </th>
          </tr>
        )}
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            Level
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            : {levelName}
          </th>
        </tr>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            Provinsi
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            :{" "}
            {!reportInfo?.province
              ? "Semua Provinsi"
              : reportInfo?.province?.split("|")[1]}
          </th>
        </tr>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            Kab/Kota
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            :{" "}
            {!reportInfo?.district
              ? "Semua Kab/Kota"
              : reportInfo?.district?.split("|")[1]}
          </th>
        </tr>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0 }}>Fasyankes</th>
          <th style={{ border: "none", paddingTop: 0 }}>
            : {!reportInfo?.fasyankes ? "Semua Fasyankes" : result}
          </th>
        </tr>
      </table>
      {renderTableByLevel()}
    </div>
  );
};

export default TableComponent;
