import React, { Component } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import styles from "./Login.module.scss";
import InputField from "components/atoms/InputField";
import Button from "components/atoms/Button";
import { ArrowRight } from "react-feather";
import LogoKemkes from "assets/logo/kemkes_logo.svg";
import { loggedIn, loggedOut } from "redux/actions/authAction";
import { showNotif } from "redux/actions/showNotifAction";
import { connect } from "react-redux";
import Auth from "services/Auth";
import { decodeToken } from "utils/common";
import { toast } from "react-toastify";
// import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { FiEye } from "react-icons/fi";
import AlertMessage from "components/AlertMessage";
import config from "utils/config";

const validationSchema = Yup.object().shape({
  username: Yup.string().required("Username wajib diisi"),
  password: Yup.string().required("Password wajib diisi"),
});

const AuthService = new Auth();

class Login extends Component {
  componentDidMount() {
    window.document.title = "SISMAL | Login";
  }

  render() {
    return (
      <div className={styles.pageWrapper}>
        <img src={LogoKemkes} alt="" width="200px" />
        <div className={styles.loginCardWrapper}>
          <h1>Selamat Datang di SISMAL</h1>
          <p>SISTEM INFORMASI MALARIA</p>

          {config.appMode === "development" && (
            <AlertMessage message="Ini adalah aplikasi latihan" />
          )}

          {/* Formik Library start */}
          <Formik
            initialValues={{
              username: "",
              password: "",
            }}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting }) => {
              const payload = {
                username: values.username,
                password: values.password,
              };

              await AuthService.loggedIn(payload)
                .then((token) => {
                  try {
                    const user = decodeToken(token);
                    this.props.showNotif({ show: true });
                    this.props.loggedIn({
                      token,
                      currentUser: { ...user, isAuthenticated: true },
                    });
                    window.browserHistory.push("/");
                  } catch (error) {
                    console.log(err);
                  }
                })
                .catch((error) => {
                  toast.error(error.response.data.message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: 0,
                    theme: "colored",
                  });
                });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <p>Silahkan login menggunakan Username dan Password </p>
                <div className={styles.formContainer}>
                  <InputField
                    type={"text"}
                    name={"username"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.username}
                    placeholder={"Username"}
                    error={errors.username && touched.username}
                    errorMessage={errors.username}
                  />
                  <InputField
                    type={"password"}
                    name={"password"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    placeholder={"Password"}
                    error={errors.password && touched.password}
                    errorMessage={errors.password}
                  />
                  <Button
                    type={"submit"}
                    variant={"primary"}
                    size={"normal"}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Mohon tunggu" : "Log In"}
                  </Button>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Link
                      to="/term-condition"
                      style={{ textDecoration: "none", textAlign: "center" }}
                    >
                      <Button type={"button"} variant={"tertiary"} size={"normal"}>
                        Syarat dan Ketentuan <ArrowRight />
                      </Button>
                    </Link>
                  </div>                  
                </div>
                
              </form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loggedIn: (payload) => dispatch(loggedIn(payload)),
    loggedOut: () => dispatch(loggedOut()),
    showNotif: (payload) => dispatch(showNotif(payload)),
  };
};

export default connect(null, mapDispatchToProps)(Login);
