import React, { Component } from "react";
import { PlusCircle } from "react-feather";
import { TableComponent } from "components";

export default class Table extends Component {
  render() {
    const { data, changeMode, loading, onEdit, onRemove, onPageChange } = this.props;

    return (
      <div>
        <div className="row">
          <div className="col-sm-8">
            <button
              className="btn btn-teal font-weight-bold"
              onClick={() => changeMode("add")}
            >
              <PlusCircle className="mr-2" />
              Tambah Data
            </button>
          </div>
        </div>

        <TableComponent
          loading={loading}
          data={data?.logistic_expired_stocks}
          headers={[
            "Provinsi",
            "Tahun",
            "Bulan",
            "Jenis Logistik",
            "Laporan",
            "Jumlah"
          ]}
          cols={[
            ["province_id", "|", 1],
            "year",
            "month",
            "logistic_type",
            "report",
            "total"
          ]}
          btnEdit={{ onClick: onEdit }}
          btnRemove={{ onClick: onRemove }}
          currentPage={data?.currentPage}
          total={data?.total}
          limit={data?.limit}
          nextPage={data?.nextPage}
          previousPage={data?.previousPag}
          onPageChange={onPageChange}
          withoutActionBtn={true}
        />
        
      </div>
    );
  }
}
