import React from "react";
import { monthConverter } from "utils/common";

const TableComponent = ({
  reportsData,
  currentLevel,
  tableRef,
  reportInfo,
}) => {
  const calculateTotal = () => {
    return reportsData.reduce(
      (total, item) => {
        total.total_malaria_unchecked +=
          parseInt(item?.total_malaria_unchecked) || 0;
        total.up5_jum_neg_mikros += parseInt(item?.up5_jum_neg_mikros) || 0;
        total.up5_jum_neg_rdt += parseInt(item?.up5_jum_neg_rdt) || 0;
        total.bw5_jum_neg_mikros += parseInt(item?.bw5_jum_neg_mikros) || 0;
        total.bw5_jum_neg_rdt += parseInt(item?.bw5_jum_neg_rdt) || 0;
        total.pos_mal += parseInt(item?.pos_mal) || 0;
        total.bw5_mikros_pe += parseInt(item?.bw5_mikros_pe) || 0;
        total.bw5_rdt_pe += parseInt(item?.bw5_rdt_pe) || 0;
        total.at5_mikros_pe += parseInt(item?.at5_mikros_pe) || 0;
        total.at5_rdt_pe += parseInt(item?.at5_rdt_pe) || 0;

        return total;
      },
      {
        total_malaria_unchecked: 0,
        up5_jum_neg_mikros: 0,
        up5_jum_neg_rdt: 0,
        bw5_jum_neg_mikros: 0,
        bw5_jum_neg_rdt: 0,
        pos_mal: 0,
        bw5_mikros_pe: 0,
        bw5_rdt_pe: 0,
        at5_mikros_pe: 0,
        at5_rdt_pe: 0,
      }
    );
  };

  const total = calculateTotal();

  let levelName = "";
  if (currentLevel === "national") {
    levelName = "Nasional";
  } else if (currentLevel === "province") {
    levelName = "Provinsi";
  } else if (currentLevel === "district") {
    levelName = "Kabupaten/Kota";
  } else {
    levelName = "Fasyankes";
  }

  let periodeName = "";
  if (reportInfo?.statusPeriode === "yearly") {
    periodeName = "Tahunan";
  } else {
    periodeName = "Bulanan";
  }

  let data = reportInfo?.fasyankes;
  let parts = data?.split(",");
  let faskesName = parts?.map(function (part) {
    return part?.split("|")[1];
  });
  let result = faskesName?.join(", ");

  return (
    <div
      className="table-responsive"
      style={{ height: "560px", overflow: "scroll" }}
      ref={tableRef}
    >
      <table>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none" }}>
            <h3>SISMAL v3, REKAP REGMAL 2</h3>
          </th>
        </tr>
      </table>
      <table>
        <tr style={{ border: "none" }}>
          <th
            style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}
            width="200"
          >
            Periode Laporan
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            : {periodeName}
          </th>
        </tr>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            Tahun
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            : {reportInfo?.periode?.year}
          </th>
        </tr>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            Bulan
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            : {monthConverter(reportInfo?.periode?.month_start)} -{" "}
            {monthConverter(reportInfo?.periode?.month_end)}
          </th>
        </tr>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            Level
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            : {levelName}
          </th>
        </tr>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            Provinsi
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            :{" "}
            {!reportInfo?.province
              ? "Semua Provinsi"
              : reportInfo?.province?.split("|")[1]}
          </th>
        </tr>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            Kab/Kota
          </th>
          <th style={{ border: "none", paddingTop: 0, paddingBottom: 0 }}>
            :{" "}
            {!reportInfo?.district
              ? "Semua Kab/Kota"
              : reportInfo?.district?.split("|")[1]}
          </th>
        </tr>
        <tr style={{ border: "none" }}>
          <th style={{ border: "none", paddingTop: 0 }}>Fasyankes</th>
          <th style={{ border: "none", paddingTop: 0 }}>
            : {!reportInfo?.fasyankes ? "Semua Fasyankes" : result}
          </th>
        </tr>
      </table>
      <table>
        <thead>
          <tr>
            <th>No.</th>
            {currentLevel === "fasyankes" && <th>Sumber Data</th>}
            <th>Provinsi</th>
            <th>Kab/Kota</th>
            {currentLevel === "district" && (
              <>
                <th>Faskes Pencatat</th>
              </>
            )}
            {currentLevel === "fasyankes" && (
              <>
                <th>Faskes Pencatat</th>
                <th>Tahun Laporan</th>
                <th>Bulan Laporan</th>
                <th>Asal Penemuan</th>
                <th>SSR</th>
                <th>Kegiatan Penemuan</th>
                <th>Tanggal MBS</th>
                <th>Petugas</th>
                <th>Kader</th>
              </>
            )}
            <th>
              Jumlah suspek <br /> malaria <br /> yang tidak <br /> dilakukan{" "}
              <br /> pemeriksaan
            </th>
            <th>
              Jumlah Pemeriksaan <br /> Mikroskopis (Negative) <br /> Usia{" "}
              {">="} 5. (Regmal 2)
            </th>
            <th>
              Jumlah Pemeriksaan <br /> RDT (Negative) <br /> Usia {">="} 5. (Regmal 2)
            </th>
            <th>
              Jumlah Pemeriksaan <br /> Mikroskopis (Negative) <br /> Usia {"<"}{" "}
              5. (Regmal 2)
            </th>
            <th>
              Jumlah Pemeriksaan <br /> RDT (Negative) <br /> Usia {"<"} 5. (Regmal 2)
            </th>
            <th>
              Positif Malaria <br />(Regmal 1)<br /> (Pemeriksaan Mikroskopis + RDT)
            </th>
            <th>
              Jumlah Pemeriksaan <br /> Mikroskopis (Negatif) <br /> Usia {"<"}{" "}
              5. (PE)
            </th>
            <th>
              Jumlah Pemeriksaan <br /> RDT (Negatif) <br /> Usia {"<"} 5.{" "} (PE)
            </th>
            <th>
              Jumlah Pemeriksaan <br /> Mikroskopis (Negatif) <br /> Usia {">="}{" "} (PE)
              5.
            </th>
            <th>
              Jumlah Pemeriksaan <br /> RDT (Negatif) <br /> Usia {">="} 5.{" "} (PE)
            </th>
            <th>
              Total Suspek <br /> (Suspek Malaria <br /> yang tidak dilakukan{" "}
              <br /> pemeriksaan ditambah <br /> jumlah total <br />{" "}
              pemeriksaan(pos+neg))
            </th>
          </tr>
        </thead>
        <tbody>
          {reportsData ? (
            reportsData?.map((item, index) => (
              <tr key={item?.id}>
                <td>{index + 1}</td>
                {currentLevel === "fasyankes" && <td>{item?.data1}</td>}
                <td>{item?.namaprop}</td>
                <td>{item?.namakab ?? "-"}</td>
                {currentLevel === "district" && (
                  <>
                    <td>{item?.namafaskes}</td>
                  </>
                )}
                {currentLevel === "fasyankes" && (
                  <>
                    <td>{item?.namafaskes}</td>
                    <td>{item?.year_report}</td>
                    <td>{item?.month_report}</td>
                    <td>{item?.case_from}</td>
                    <td>{item?.sr_id}</td>
                    <td>{item?.discovery_activities}</td>
                    <td>{item?.mbs_date}</td>
                    <td>{item?.petugas}</td>
                    <td>{item?.kader}</td>
                  </>
                )}
                <td>{parseInt(item?.total_malaria_unchecked ?? 0)}</td>
                <td>{parseInt(item?.up5_jum_neg_mikros ?? 0)}</td>
                <td>{parseInt(item?.up5_jum_neg_rdt ?? 0)}</td>
                <td>{parseInt(item?.bw5_jum_neg_mikros ?? 0)}</td>
                <td>{parseInt(item?.bw5_jum_neg_rdt ?? 0)}</td>
                <td>{parseInt(item?.pos_mal ?? 0)}</td>
                <td>{parseInt(item?.bw5_mikros_pe ?? 0)}</td>
                <td>{parseInt(item?.bw5_rdt_pe ?? 0)}</td>
                <td>{parseInt(item?.at5_mikros_pe ?? 0)}</td>
                <td>{parseInt(item?.at5_rdt_pe ?? 0)}</td>
                <td>
                  {parseInt(item?.total_malaria_unchecked ?? 0) +
                    parseInt(item?.up5_jum_neg_mikros ?? 0) +
                    parseInt(item?.up5_jum_neg_rdt ?? 0) +
                    parseInt(item?.bw5_jum_neg_mikros ?? 0) +
                    parseInt(item?.bw5_jum_neg_rdt ?? 0) +
                    parseInt(item?.pos_mal ?? 0) +
                    parseInt(item?.bw5_mikros_pe ?? 0) +
                    parseInt(item?.bw5_rdt_pe ?? 0) +
                    parseInt(item?.at5_mikros_pe ?? 0) +
                    parseInt(item?.at5_rdt_pe ?? 0)}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={13}>Tidak Ada Data</td>
            </tr>
          )}
          <tr style={{ backgroundColor: "#43766C" }}>
            {currentLevel === "national" && (
              <td style={{ color: "#FFF" }} colSpan={3}>
                TOTAL
              </td>
            )}
            {currentLevel === "province" && (
              <td style={{ color: "#FFF" }} colSpan={3}>
                TOTAL
              </td>
            )}
            {currentLevel === "district" && (
              <td style={{ color: "#FFF" }} colSpan={4}>
                TOTAL
              </td>
            )}
            {currentLevel === "fasyankes" && (
              <td style={{ color: "#FFF" }} colSpan={13}>
                TOTAL
              </td>
            )}
            <td style={{ color: "#fff" }}>{total?.total_malaria_unchecked}</td>
            <td style={{ color: "#fff" }}>{total?.up5_jum_neg_mikros}</td>
            <td style={{ color: "#fff" }}>{total?.up5_jum_neg_rdt}</td>
            <td style={{ color: "#fff" }}>{total?.bw5_jum_neg_mikros}</td>
            <td style={{ color: "#fff" }}>{total?.bw5_jum_neg_rdt}</td>
            <td style={{ color: "#fff" }}>{total?.pos_mal}</td>
            <td style={{ color: "#fff" }}>{total?.bw5_mikros_pe}</td>
            <td style={{ color: "#fff" }}>{total?.bw5_rdt_pe}</td>
            <td style={{ color: "#fff" }}>{total?.at5_mikros_pe}</td>
            <td style={{ color: "#fff" }}>{total?.at5_rdt_pe}</td>
            <td style={{ color: "#fff" }}>{
              total?.total_malaria_unchecked +
              total?.up5_jum_neg_mikros +
              total?.up5_jum_neg_rdt +
              total?.bw5_jum_neg_mikros +
              total?.bw5_jum_neg_rdt +
              total?.pos_mal +
              total?.bw5_mikros_pe +
              total?.bw5_rdt_pe +
              total?.at5_mikros_pe +
              total?.at5_rdt_pe
            }</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TableComponent;
